//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useStore, ref, useContext, useFetch } from '@nuxtjs/composition-api'
import { mapGetters, mapState } from 'vuex'
import VideoReviewCard from '~/components/video-reviews/VideoReviewCard'
import { keysToCamel } from '~/utils/helpers'
import { APP_RATING } from '~/constants'

export default defineComponent({
    name: 'IndReviews',
    components: { VideoReviewCard },
    fetchOnServer: false,
    setup () {
        const { $api, $handleErrors } = useContext()
        const store = useStore()

        const reviews = ref(null)

        const getReviews = async (page = 1) => {
            try {
                const response = await $api.get('guests/testimonials/video-list', { page, per_page: 6 })
                reviews.value = keysToCamel(response)
                store.commit('app/setVideoReviewsCount', reviews.value?.meta?.totalItems)
            } catch (err) {
                $handleErrors(err)
            }
        }

        useFetch(() => getReviews())

        return {
            reviews,
            getReviews,
            appRating: APP_RATING
        }
    },
    computed: {
        ...mapGetters(['isMobile']),
        ...mapState({
            totalReviewsCount: state => state.app.reviewsCount && state.app.videoReviewsCount && (+state.app.reviewsCount + +state.app.videoReviewsCount)
        }),
        hasRestriction () {
            return this.reviews.meta.currentPage >= 10
        }
    }
})
