//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'IndAward',
    computed: {
        ...mapGetters(['isMobile'])
    }
})
