//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
    name: 'IndTopSellers',
    computed: {
        ...mapGetters(['isMobile'])
    }
}
