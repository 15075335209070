//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { addHighlight } from '~/utils/helpers'
import { toEntities } from '~/utils/html-entities'

export default {
    name: 'VideoReviewCard',
    props: {
        onlyThumb: {
            type: Boolean,
            default: false
        },
        animation: {
            type: Boolean,
            default: false
        },
        review: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters(['isMobile']),
        poster () {
            return this.isMobile
                ? this.review.video?.conversionsImages?.thumbSmall
                : this.animation
                    ? this.review.video?.urlAnimatedPreview
                    : this.review.video?.conversionsImages?.thumbBig
        },
        transcription () {
            return this.animation && !this.isMobile
                ? this.review.textStriped
                : ''
        }
    },
    methods: {
        toEntities,
        addHighlight
    }
}
