//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, onMounted, onUnmounted, reactive } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'IndBlocks',
    setup () {
        const steps = reactive([
            {
                text: 'Create free account',
                image: '/assets-outside/img/index_background/sell-step-296-1.webp',
                show: false
            },
            {
                text: 'Verify your identity',
                image: '/assets-outside/img/index_background/sell-step-452-2.webp',
                show: false
            },
            {
                text: 'Get orders and deliver traffic',
                image: '/assets-outside/img/index_background/sell-step-530-3.webp',
                show: false
            },
            {
                text: 'Receive a payment from Udimi',
                image: '/assets-outside/img/index_background/sell-step-436-4.webp',
                show: false
            }
        ])

        const endBlock = ref(null)

        const scrollHandler = () => {
            const endBlockComponent = endBlock.value.getClientRects()[0]
            if (endBlockComponent?.top < 700) {
                window.removeEventListener('scroll', scrollThrottler, false)
                steps.forEach((step, index) => {
                    setTimeout(() => {
                        step.show = true
                    }, index * 300)
                })
            }
        }

        let scrollTimeout

        const scrollThrottler = () => {
            if (!scrollTimeout) {
                scrollTimeout = setTimeout(() => {
                    scrollTimeout = null
                    scrollHandler()
                }, 66)
            }
        }

        onMounted(() => {
            window.addEventListener('scroll', scrollThrottler, false)
        })

        onUnmounted(() => {
            window.removeEventListener('scroll', scrollThrottler, false)
        })

        return {
            steps,
            endBlock
        }
    },
    computed: {
        ...mapGetters(['isMobile'])
    }
})
